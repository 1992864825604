#editUserForm {
  max-width: 800px !important;
}

#permissionsDiv {
  margin: 0.5rem 1.5rem;
  line-height: 1.5;
}

#editUserForm .buttonDiv {
  grid-column: span 4;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  text-align: center;
  margin-top: 2rem;
}

#editUserForm button {
  width: 200px;
}

.permissionLabel {
  font-size: 13px;
  opacity: 0.9;
}

.userSearchFilterDiv {
  display: flex;
}

#usersSearchDiv {
  display: grid;
  gap: 0.2rem;
  grid-template-columns: minmax(200px, 60%) minmax(150px, 20%) minmax(150px, 20%);
  margin-bottom: 1rem;
}

#usersListDiv {
  margin-bottom: 1rem;
}
