#organisation-edit {
  width: 800px;
}

.email-domain-container {
  display: flex;
}

.email-domain-input-label {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #e9ecef;
  margin-bottom: 20px;
  border: none !important;
}

.email-domain-container .validated-input {
  margin-bottom: 20px !important;
}

#organisation-edit .dynamic-list-delete-btn {
  margin-bottom: 20px !important;
}
