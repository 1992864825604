#pbaas-app-overview {
    width:80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:20px;
}

#pbaas-app-overview .validated-input{
    margin-bottom: 20px;
}