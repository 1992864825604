#app {
  margin-left: 240px;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
}

#appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
