.ampFormLabel {
  font-size: 15px;
  align-self: center;
  text-align: center;
  line-height: 1.5;
  color: black;
  font-weight: 500;
  opacity: 0.8;
}

.ampFormOptionGroupHeading {
  font-size: 13px;
  font-weight: bold;
  color: #6c757d !important;
  margin-bottom: 0 !important;
}

.ampFormControl {
  display: block;
  width: 100%;
  line-height: 1 !important;
  font-size: 0.9rem !important;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  padding: 10px;
  outline-width: 1px;
  outline-color: #ddd;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ampFormControl:disabled,
.ampFormControl[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.ampFormSelect {
  display: block;
  width: 100%;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 10px;
  outline-width: 1px;
  outline-color: #ddd;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

.errorMsg {
  color: red;
}
