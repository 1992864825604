.pbaas-app-ui {
    min-width: 1100px;
}

.pbaas-app-ui .designers {
    width : 700px;
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
}

.pbaas-app-ui .designers > div {
    margin-bottom: 30px;
}

.pbaas-app-ui .controls {
    position: sticky;
    top: 30px;
    width: 300px;
    display: inline-block;
    vertical-align: top;
}

.pbaas-app-ui .controls  > div {
    margin-bottom: 10px;
}

