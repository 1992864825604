.selectable-element {
    height: 100%;
    width: 100%;
    border: 2px dashed transparent;
    cursor: pointer;
}

.selectable-element.selected {
    border: 2px dashed #444;
    cursor: default;
}