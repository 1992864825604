#logsTable .expandedRowParent {
  background-color: rgb(215, 215, 205);
}

.logitemContainer span {
  font-weight: 600;
}

tr.expandedRow + tr {
  background-color: rgba(0, 0, 0, 0.05);
}

#logsTable .expandedRow {
  background-color: white;
  font-size: 0.9rem;
}

.logitemContainer {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 150px 1fr;
}

.logitemContainer > hr {
  grid-column: span 2;
}

#filtersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

#filtersContainer #searchBtnDiv {
  grid-column: span 3;
  justify-content: center;
  display: flex;
}

#logsCountLabel {
  margin-bottom: 1rem;
  opacity: 1;
  color: #6c757d !important;
}

#searchButtonDiv {
  grid-column: span 3;
  gap: 5px;
  display: flex;
  flex-direction: row;
  justify-self: center;
  text-align: center;
  margin-top: 0.5rem;
}

#dialogModal {
  min-width: 80%;
  width: max-content;
}

#dialogModal div {
  width: max-content;
  margin: auto;
  padding: 1rem;
}

#dialogModal .ampCommonContainer {
  border: none;
}
