.property-grid-color-editor {
    position: relative;
    cursor: pointer;
}

.property-grid-color-editor .color-text {
    display: inline-block;
    vertical-align: middle;
    width: 65px;
    text-transform: uppercase;
}

.property-grid-color-editor .color-display {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border: 1px solid #777; 
}

.property-grid-color-editor .popover {
    position: absolute;
    top: 30px;
}

.property-grid-color-editor .cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}