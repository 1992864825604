.property-grid table {
    width: 100%;
}
.property-grid th, .property-grid td{
    padding: 5px;
}

.property-grid td {
    border: 1px solid #aaa;
}

.property-grid .property-grid-input {
    padding:1px;
    border: none;
    outline: none;
    margin: 0;
    width:100%;
}

.property-grid thead {
    background-color: #005;
    color: #fff;
}

.property-grid .property-name {
    font-weight: 600;
}

.property-grid .property-value {
    background-color: #fff;
}

