.pin-pad-designer {
    height:600px;
    width:300px;
    border: 1px solid #666
}

.pin-pad-designer .title {
    height:5%;
}

.pin-pad-designer .body {
    height:45%;
}

.pin-pad-designer .keypad {
    height:50%;
}

.pin-pad-designer .body .text-container {
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}