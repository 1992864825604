.list .heading {
  color: #555;
  font-weight: 500;
  background-color: #fff;
  padding: 6px;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #ddd;
}

.list ul {
  list-style-type: none;
  padding: 0;
}

.list ul li {
  padding: 6px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.list ul li:hover {
  background-color: #eee;
  font-weight: 500;
}

.list ul li a {
  display: block;
  width: 100%;
}
