.dialog-designer {
    height: 200px;
    width:400px;
    padding: 20px;
    border: 1px solid #333;
    border-radius: 3px;
}

.dialog-designer .title {
    height: 25%;
    font-weight: 600;
}

.dialog-designer .body {
    height: 40%;
}


.dialog-designer .button-area {
    height: 35%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
