.image-button .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
}

.image-button .image-container img {
    height: 30px;
    width: 30px;
}