.dynamic-list-item {
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  flex-wrap: wrap;
  align-items: center !important;
}

#dynamic-list-add-btn {
  min-width: 100px;
  background-color: #08e;
  color: white;
  margin-top: 5px;
}

.dynamic-list-delete-btn {
  min-width: 100px;
  background-color: #c33;
  color: white;
}

.dynamic-list-btn {
  width: max-content;
  -webkit-appearance: button;
  appearance: button;
  padding: 8px 16px 8px 16px;
  color: #eee;
  border: none;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 600;
  box-shadow: 0 0 0 0 rgb(71 66 66 / 0%);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  width: max-content !important;
  height: max-content !important;
}

.dynamic-list-btn:hover {
  color: #fff;
  cursor: pointer;
  box-shadow: 2px 2px 3px 1px rgba(71, 66, 66, 0.3);
}
