.breadcrumb {
    font-size: 16px;
    border-bottom: solid 1px #aaa;
    margin-bottom: 10px;
    width: 100%;
    padding:6px
}

.breadcrumb > span {
    padding-right: 8px;
    line-height: 40px;
}

.breadcrumb .page-title {
    font-weight: 600;
    color: #666;
}