#brand {
  display: flex;
  height: 60px;
  padding: 10px;
  margin-bottom: 30px;
}

#sidebar {
  position: fixed;
  overflow: hidden auto;
  z-index: 100;
  height: 100%;
  width: 240px;
  box-shadow: 1px 1px 2px 1px rgba(47, 47, 47, 0.2);
  background: white;
  line-height: 1;
  font-size: 15px;
}

#sidebar ul {
  list-style-type: none;
  padding-inline-start: 20px;
}

#sidebar li {
  width: max-content;
  height: 35px;
  padding-right: 10px;
}

#sidebar a {
  text-decoration: none;
  color: #666;
}

#sidebar a.active,
#sidebar a:hover {
  font-weight: 500;
  color: #000;
}

.sidebar-icon {
  padding: 0 !important;
}

.sidebar-text {
  padding-left: 10px;
}
