.link-button a {
    appearance: button;
    padding: 6px 16px 8px 16px; 
    color: #eee;
    text-decoration: none;
    border-radius: 3px;
    font-weight: 600;
    box-shadow: 0 0 0 0 rgba(71, 66, 66, 0.0);
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    width: max-content !important;
}

.link-button a.blue { background-color: #08E; }
.link-button a.blue:hover { background-color: #19F; }

.link-button a.red { background-color: #c33; }
.link-button a.red:hover { background-color: #d44; }

.link-button a.grey { background-color: #444; }
.link-button a.grey:hover { background-color: #555; }

.link-button a:hover {
    color:#fff;
    cursor: pointer;
    box-shadow: 2px 2px 3px 1px rgba(71, 66, 66, 0.3);
}

.link-button a.disabled {
    background-color: #ccc;
    color: #777;
    pointer-events: none;
    cursor: default;
}