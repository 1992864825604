.ampTable {
  width: 100%;
  margin-bottom: 0.1rem;
  text-align: start;
  line-height: 1;
}

.ampTable th {
  background-color: #fff;
  color: #555;
}

.ampTable th,
.ampTable td {
  padding: 0.5rem;
  border-bottom: 2px #F3F2F1 solid;
}

.ampTable.nowrap td,
.ampTable.nowrap th {
  white-space: nowrap;
  padding: 8px 10px;
  text-align: start;
  text-indent: inherit;
}

.ampTable tr:hover {
  background-color: rgb(215, 215, 205);
}