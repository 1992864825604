
.amp-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.325);
    flex: 1 1 auto;
    padding: 3rem 2rem;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    overflow: auto;
    overflow-wrap: break-word;
    -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
    box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
}

.amp-container .amp-heading {
    margin-top: -20px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #444;
}

.amp-container.center > div {
    margin: auto;
}

