/*
* High Level common layout css
*/

.ampCommonContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.325);
  flex: 1 1 auto;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
  overflow: auto;
  overflow-wrap: break-word;
  -webkit-box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
}

.ampPageHeading {
  font-size: 1rem;
  text-align: left;
}

/*
* Modifications to defaul behaviours
*/

.form-control,
.form-select {
  line-height: 1 !important;
  font-size: 0.9rem !important;
}

.dropdown-item:focus {
  background-color: inherit !important;
}

