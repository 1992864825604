.ampClickable {
  cursor: pointer;
}
@media screen and (max-width: 1400px) {
  .ampSmallScreenMarginStart {
    margin-left: 80px;
  }
}

.ampMarginNone {
  margin: 0;
}

.ampMarginSmall {
  margin: 1rem;
}

.ampFontSizeSmall {
  font-size: 0.9rem;
}

.ampFontSizeLarge {
  font-size: 1.1rem;
}

.ampFlexCenter {
  display: flex;
  justify-content: center;
}

.ampLink {
  color: #0d6efd;
}
