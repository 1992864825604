.editable-grid {
    width: 100%;
}

.editable-grid td {
    padding: 0; 
    border: 1px solid #777;
    position: relative;
}

.editable-grid td div.value {
    vertical-align: middle;
    padding: 10px;
    background-color: #ddd;
    font-weight: 600;
}

.editable-grid td input {
    width: 100%;
    padding: 10px;
    border: none;
}

.editable-grid td .error-container {
    position: absolute;
    right:10px;
    top:10px;
    color: #f00;
    background-color: rgba(255,255,255,.8);
}