.dropdown-toggle::after {
  color: black;
}

#topbar {
  height: 60px;
  z-index: 1;
  background-color: white;
  line-height: 1.5;
  margin: 0 !important;
  padding: 0 !important;
}

#logoutIcon {
  padding-left: 0.2rem;
  cursor: pointer;
}

#topbarContainer {
  max-width: 80%;
  display: flex;
  justify-content: end;
}
