.alert-button {
  appearance: button;
  padding: 6px 16px 8px 16px;
  margin-left: 10px;
  color: #eee;
  border: none;
  text-decoration: none;
  border-radius: 3px;
  font-weight: 600;
  box-shadow: 0 0 0 0 rgba(71, 66, 66, 0);
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
  width: max-content !important;
}

.alert-button.blue {
  background-color: #08E;
}
.alert-button.blue:hover {
  background-color: #19F;
}

.alert-button.red {
  background-color: #c33;
}
.alert-button.red:hover {
  background-color: #d44;
}

.alert-button.grey {
  background-color: #444;
}
.alert-button.grey:hover {
  background-color: #555;
}

.alert-button:hover {
  color: #fff;
  cursor: pointer;
  box-shadow: 2px 2px 3px 1px rgba(71, 66, 66, 0.3);
}
