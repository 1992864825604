#user-edit {
    width:800px
}

#permission-container {
    display: table;
}

#permission-container > div {
    display: table-cell;
}

#permission-container #permission-list-container {
    width:300px;
}

#permission-container #permission-description {
    vertical-align: middle;
    font-size: 18px;
    color: #444;
}