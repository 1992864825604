.pin-pad-key-pad .button-container {
    display: grid;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
}

.pin-pad-key-pad .button-container > div {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.key-pad-button {
    border: 1px solid #fff;
}

.key-pad-button.button1 {
    border: none;
}

.key-pad-button.button2 {
    border-top: none;
    border-bottom: none;
}

.key-pad-button.button3 {
    border: none;
}

.key-pad-button.button4 {
    border-left: none;
    border-right: none;
}

.key-pad-button.button6 {
    border-left: none;
    border-right: none;
}

.key-pad-button.button7 {
    border-top: none;
    border-left: none;
    border-right: none;
}

.key-pad-button.button8 {
    border-top: none;
}

.key-pad-button.button9 {
    border-top: none;
    border-left: none;
    border-right: none;
}

.key-pad-button.button0 {
    border-top: none;
    border-bottom: none;
}