#dashboardDiv {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  gap: 0.2rem;
}

#rangeFilterDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
}

#rangeFilterDiv select {
  width: fit-content;
  line-height: 1.5;
  padding-right: 30px;
}
